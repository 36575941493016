import React from 'react';
import { Description, Header, Map, Wrapper } from './OfficeLocation.styled';

interface Props {
  img: string;
  link: string;

  title: string;

  children?: React.ReactNode;
}

/**
 * Shows the location of the office with an image and description
 *
 * @param {string} title
 * @param {string} description
 * @param {string} img URL of the image
 * @param {string} link Google URL for the office location
 */
const OfficeLocation: React.FunctionComponent<Props> = ({
  img,
  link,
  title,
  children,
}: Props) => {
  return (
    <Wrapper>
      <div>
        <Header>{title}</Header>
        <Description>{children}</Description>
      </div>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
      >
        <Map src={img} alt={`Map of ${title}`} />
      </a>
    </Wrapper>
  );
};

export default OfficeLocation;
