import React from 'react';
import { MdEmail } from 'react-icons/md';
import Obfuscate from 'react-obfuscate';
import Container from 'src/Components/ContentContainer';
import OfficeLocation from 'src/Components/OfficeLocation';
import SEO from 'src/Components/SEO';
import AdelaideSrc from 'src/images/contact-us/adelaide.png';
import RsocSrc from 'src/images/contact-us/rsoc.png';
import BoulderSrc from 'src/images/contact-us/boulder.png';
import ColoradoSpringsSrc from 'src/images/contact-us/coloradosprings.png';
import SydneySrc from 'src/images/contact-us/sydney.png';
import Layout from 'src/Layout';
import * as Base from 'src/page-styles/base.styled';
import styled from 'styled-components';

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  margin-top: 3rem;

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    margin-top: 4rem;
    gap: 4rem;

    & .contact-form {
      justify-self: end;
    }
    & .media-form {
      justify-self: start;
    }
  }
`;

const FormObj = styled.div`
  background-color: ${({ theme }) => theme.grey800};
  box-sizing: border-box;

  padding: 1.5rem 1rem;

  margin-inline: auto;

  @media only screen and (min-width: 768px) {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-sizing: content-box;
    padding: 2rem;
  }

  @media only screen and (min-width: 1200px) {
    width: 20rem;
    height: 8rem;
  }
`;

const OfficeCol = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 3rem;

  @media only screen and (min-width: 1200px) {
    grid-column: 1 / span 2;
  }
`;

function ContactForm(): JSX.Element {
  const contactEmail = 'info@saberastro.com';
  return (
    <FormObj>
      <Base.Paragraph>
        Send us an email, we endeavour to answer all inquiries within 24 hours
        on business days.
      </Base.Paragraph>

      <Base.Paragraph
        style={{ display: 'flex', alignItems: 'center', marginBottom: '0' }}
      >
        <MdEmail style={{ marginRight: '0.5rem', fontSize: '1.5rem' }} />
        <Obfuscate
          email={contactEmail}
          style={{ color: 'white', textDecoration: 'none' }}
        />
      </Base.Paragraph>
    </FormObj>
  );
}

export default function ContactUs(): JSX.Element {
  return (
    <>
      <SEO
        title="Contact | Saber Astronautics"
        description="We love to hear from you! Feel free to send us any questions, ideas and feedback."
        url="https://www.saberastro.com/contact-us"
      />
      <Layout>
        <Container.Dark>
          <Base.MenuGap />
          <Base.Heading>CONTACT US</Base.Heading>
          <Columns>
            <div className="contact-form">
              <ContactForm />
            </div>
            <div className="media-form">
              <FormObj>
                <Base.Paragraph>
                  For any media enquiries, please email{' '}
                  <Obfuscate
                    email="media@saberastro.com"
                    style={{ color: 'white', textDecoration: 'none' }}
                  />
                </Base.Paragraph>
              </FormObj>
            </div>
            <OfficeCol className="column-item">
              <OfficeLocation
                title="Mission Control Centre"
                img={RsocSrc}
                link="https://goo.gl/maps/FziojSJdgWGs6hEy7"
              >
                <Base.Paragraph>
                  Australian Space Discovery Centre
                </Base.Paragraph>
                <Base.Paragraph>McEwin Building</Base.Paragraph>
                <Base.Paragraph>Lot Fourteen, North Terrace</Base.Paragraph>
                <Base.Paragraph>Adelaide, SA 5000</Base.Paragraph>
                <Base.Paragraph className="country">Australia</Base.Paragraph>
              </OfficeLocation>
              <OfficeLocation
                title="Adelaide, Australia"
                img={AdelaideSrc}
                link="https://goo.gl/maps/QsgY4ZudqLrzgNbg7"
              >
                <Base.Paragraph>Ground Floor, Bice Building</Base.Paragraph>
                <Base.Paragraph>Lot Fourteen, North Terrace</Base.Paragraph>
                <Base.Paragraph>Adelaide, SA 5000</Base.Paragraph>
                <Base.Paragraph className="country">Australia</Base.Paragraph>
              </OfficeLocation>
              <OfficeLocation
                title="Sydney, Australia"
                img={SydneySrc}
                link="https://goo.gl/maps/dEGZUVRJqKHVMXsn8"
              >
                <Base.Paragraph>Suite 3, 63-71 Balfour Street</Base.Paragraph>
                <Base.Paragraph>Chippendale, NSW 2008</Base.Paragraph>
                <Base.Paragraph className="country">Australia</Base.Paragraph>
              </OfficeLocation>
              <OfficeLocation
                title="Boulder, Colorado"
                img={BoulderSrc}
                link="https://goo.gl/maps/bPT2qzQ3DbzeVdm49"
              >
                <Base.Paragraph>1722 14th Street, Suite 200</Base.Paragraph>
                <Base.Paragraph>Boulder, CO 80302</Base.Paragraph>
                <Base.Paragraph className="country">
                  United States
                </Base.Paragraph>
              </OfficeLocation>
              <OfficeLocation
                title="Catalyst Campus"
                img={ColoradoSpringsSrc}
                link="https://goo.gl/maps/DyduKSqaArMHSKrv9"
              >
                <Base.Paragraph>555 E Pikes Peak Ave</Base.Paragraph>
                <Base.Paragraph>Colorado Springs, CO 80903</Base.Paragraph>
                <Base.Paragraph className="country">
                  United States
                </Base.Paragraph>
              </OfficeLocation>
            </OfficeCol>
          </Columns>
        </Container.Dark>
      </Layout>
    </>
  );
}
