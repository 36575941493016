import { Heading } from 'src/page-styles/base.styled';
import styled from 'styled-components';

export const Header = styled(Heading)`
  font-size: 1.5rem;
  margin: 0.5rem 0;
  text-align: left;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column-reverse;
  /* user-select: all; */
  transition: all 0.35s ease;

  &:hover {
    transform: translateY(-16px);
  }

  @media only screen and (min-width: 768px) {
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  }
  @media only screen and (min-width: 1200px) {
    padding-inline: 15rem;
  }
`;

export const Map = styled.img`
  object-fit: cover;
  max-width: 100%;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (min-width: 768px) {
    max-width: 300px;
    width: 300px;

    max-height: 300px;
    height: 300px;
  }

  @media only screen and (min-width: 1024px) {
    max-width: 352px;
    width: 352px;

    max-height: 352px;
    height: 352px;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 224px;
    width: 224px;

    max-height: 224px;
    height: 224px;
  }
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontHeader};
  margin-top: 0.25rem;

  .country {
    font-size: 1.25rem;
  }
`;
